import React from 'react';
import { AiOutlinePhone, AiOutlineExport } from "react-icons/ai";
import { TiArrowBackOutline } from 'react-icons/ti'
import PropTypes from 'prop-types';
import { ButtonsTemplateBubbleStyled } from './styled';
import { replacePlaceholders } from './utils';



export const InteractiveExtraContent = ({ buttonsContent, buttonsVariables, disableClick }) => {
  const buttonsToPaint = buttonsContent?.buttons;

  return (
    <ButtonsTemplateBubbleStyled>
      {buttonsToPaint ?
        <div className="template-buttons-wrapper">
          {buttonsToPaint?.length && buttonsToPaint.map((el, index) => {

            const { type, text, /* url, example, phoneNumber */ } = el || {};
            let onClickComposed;
            /* if (type === 'QUICK_REPLY') {

            } */
            if (type === 'URL') {
              onClickComposed = () => window.open(replacePlaceholders(el, buttonsVariables), '_blank');
            }
            /* if (type === 'PHONE_NUMBER') {

            } */
            if (disableClick) {
              onClickComposed = null;
            }
            const CallIcon = AiOutlinePhone;
            const UrlIcon = AiOutlineExport;
            const QuickReplyIcon = TiArrowBackOutline;
            let IconJsx;
            if (type === 'URL') {
              IconJsx = UrlIcon;
            }
            if (type === 'QUICK_REPLY') {
              IconJsx = QuickReplyIcon;
            }
            if (type === 'PHONE_NUMBER') {
              IconJsx = CallIcon;
            }
            return (
              <div onClick={onClickComposed} key={el} className={`button-interactive ${((index < 2 && buttonsToPaint.length > 1)) ? 'button-not-last' : 'button-last'}`}>
                {IconJsx ? <span className='button-icon'><IconJsx/></span> : <span/>}<span>{text}</span>
              </div>
            );
          })}
        </div> : <div/>
      }
    </ButtonsTemplateBubbleStyled>
  )
};

InteractiveExtraContent.propTypes = {
  buttonsContent: PropTypes.object,
  buttonsVariables: PropTypes.object,
  disableClick: PropTypes.bool,
};
