import { useEffect, useState } from "react";
import { getAllBufferMessagesFromDB } from "../db/dbUtils"; // Importa función de lectura

export const useBufferMessages = () => {
  const [messages, setMessages] = useState([]);
  const dbChannel = new BroadcastChannel('db_updates'); // Suscribirse al canal
  useEffect(() => {
    const loadMessages = async () => {
      const msgs = await getAllBufferMessagesFromDB();
      const sortedMessages = [...msgs].sort((a, b) => a.timestamp - b.timestamp);
      console.log("Mensajes actualizados:", sortedMessages);
      setMessages(sortedMessages);
    };

    loadMessages(); // Cargar al inicio

    // Escuchar eventos de cambios en la DB
    const handleDBUpdate = async (event) => {
      console.log("Evento recibido en hook:", event.data);
      await loadMessages(); // Recargar mensajes en cada cambio
    };

    dbChannel.addEventListener("message", handleDBUpdate);

    return () => {
      dbChannel.removeEventListener("message", handleDBUpdate);
      dbChannel.close(); // Cerrar canal al desmontar
    };
  }, []);

  return messages;
};
