/* eslint-disable no-useless-escape */
import React from 'react';
import MultimediaContentTemplate from './multimediaContent-template';
import { CarouselBubbleStyled, CarouselCards } from './styled';
// eslint-disable-next-line import/no-cycle
import { InteractiveExtraContent } from './InteractiveExtraContent';

export const replacePlaceholders = (obj, variables) => {
  if (obj?.type === "BODY" || obj?.type === "HEADER") {
    let message = obj.text;
    const placeholders = message.match(/\*([^*]+)\*/g);

    if (placeholders) {
      placeholders.forEach((placeholder) => {
        const text = placeholder.slice(1, -1);
        message = message.replace(placeholder, `<strong>${text}</strong>`);
      });
    }

    const replacedMessage = message.replace(/{{\d+}}/g, (match) => {
      const index = parseInt(match.match(/\d+/)[0], 10);
      return variables?.parameters?.[index - 1]?.text || "";
    });

    return replacedMessage;
  }
  if (obj?.type === "URL") {
    const placeholder = "{{1}}";
    const value = obj.example[0];
    let { url } = obj;
    url = url.replace(placeholder, value);
    return url;
  }

  return "";
};

const getTemplateComponentFromContent = (content, elementName) => {
  return content.templateComponents?.find(el => el?.type?.toLowerCase() === elementName?.toLowerCase())
}
const getVariablesFromContent = (content, elementName) => {
  return content.components?.find(el => el?.type === elementName);
}
export const paintHeader = (_content, onMultimediaClick) => {
  const headerObjectToReplace = getTemplateComponentFromContent(_content, 'HEADER');
  if (!headerObjectToReplace) {
    return <div />;
  }
  const variablesToReplace = getVariablesFromContent(_content, 'header');
  let contentHeader;
  if (headerObjectToReplace?.format === 'TEXT') {
    contentHeader = (
      <div className="header-template-container">
        <p className="header-template-text">
          {replacePlaceholders(headerObjectToReplace, variablesToReplace)}
        </p>
      </div>
    );
  }
  if (headerObjectToReplace?.format === 'IMAGE') {
    contentHeader = (
      <div>
        <MultimediaContentTemplate
          multimediaId={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaId}
          multimediaCode={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaCode}
          onMultimediaClick={onMultimediaClick}
          image
        />
      </div>
    );
  }
  if (headerObjectToReplace?.format === 'VIDEO') {
    contentHeader = (
      <div>
        <MultimediaContentTemplate
          multimediaId={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaId}
          multimediaCode={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaCode}
          onMultimediaClick={onMultimediaClick}
          video
        />
      </div>
    );
  }
  if (headerObjectToReplace?.format === 'DOCUMENT') {
    contentHeader = (
      <div>
        <MultimediaContentTemplate
          multimediaId={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaId}
          multimediaCode={variablesToReplace?.parameters.length && variablesToReplace.parameters[0]?.multimediaCode}
          onMultimediaClick={onMultimediaClick}
          document
        />
      </div>
    );
  }
  if (headerObjectToReplace?.format === 'LOCATION') {
    const locationParameters = _content.components[0].parameters[0].location;
    contentHeader = (
      <div>
        <MultimediaContentTemplate
          locationParameters={locationParameters}
          onMultimediaClick={onMultimediaClick}
          location
        />
      </div>
    );
  }
  const jsxHeader = (
    <div>
      {contentHeader}
    </div>
  );
  return jsxHeader;
};

/* const getComponentFromCard = (card, componentName) => {
  return card?.components?.find(el => el?.type === componentName);
} */

export const paintCarousel = (_content, onMultimediaClick) => {
  const carouselObjectToReplace = getTemplateComponentFromContent(_content, 'CAROUSEL');
  
  if (!carouselObjectToReplace || !carouselObjectToReplace?.cards?.length) {
    return <div />;
  }
  const variablesToReplace = getVariablesFromContent(_content, 'carousel');
  const { cards } = carouselObjectToReplace;
  const twoFirstCards = cards.slice(0, 2);
  const cardsJsx = twoFirstCards.map((el, i) => {

    const headerPart = el?.components?.find((e) => e.type === 'HEADER');
    let headerJsx = <div/>;
    const headerInVariables = variablesToReplace?.cards?.length && variablesToReplace?.cards[i]?.components.find(e => e.type === 'header');
    if (headerPart && headerInVariables) {
      headerJsx = <MultimediaContentTemplate
      multimediaId={headerInVariables?.parameters?.length && headerInVariables?.parameters[0]?.multimediaId}
      multimediaCode={headerInVariables?.parameters?.length && headerInVariables?.parameters[0]?.multimediaCode}
      onMultimediaClick={onMultimediaClick}
      image={headerPart?.format === 'IMAGE'}
      video={headerPart?.format === 'VIDEO'}
    />
    }
    const bodyPart = el?.components?.find((e) => e.type === 'BODY');
    let bodyJsx = <div/>;
    const bodyInVariables = variablesToReplace?.cards?.length && variablesToReplace?.cards[i]?.components.find(e => e.type === 'body');
    if (bodyPart) {
      const message = replacePlaceholders(bodyPart, bodyInVariables);
      const regExpressionForStrong = /\*(.*?)\*/g;
      const regExpressionForEm = /\_(.*?)\_/g;
      const regExpressionForScratch = /\~(.*?)\~/g;
      let formattedHtml = message.replace(/\n/g, '<br>');
    
      const isBold = regExpressionForStrong.test(message);
      const isItalic = regExpressionForEm.test(message);
      const isScratched = regExpressionForScratch.test(message);
    
      if (isBold) {
        formattedHtml = formattedHtml.replace(regExpressionForStrong, "<strong>$1</strong>");
      }
      if (isItalic) {
        formattedHtml = formattedHtml.replace(regExpressionForEm, "<em>$1</em>");
      }
      if (isScratched) {
        formattedHtml = formattedHtml.replace(regExpressionForScratch, "<del>$1</del>");
      }
    
      bodyJsx = <div className="body-template" dangerouslySetInnerHTML={{ __html: formattedHtml }} />;
    }



    const buttonsJsx = el?.components?.find((e) => e.type === 'BUTTONS');
    let variablesButtons;
    if (buttonsJsx) {
      variablesButtons = variablesToReplace?.cards?.length && variablesToReplace?.cards[i]?.components.filter(e => e.type === 'button');
    }




    return (
      <div className="carousel-card-container">
      <CarouselBubbleStyled>
        {headerJsx}
        {bodyJsx}
      </CarouselBubbleStyled>
      {buttonsJsx &&
        <InteractiveExtraContent
          buttonsContent={buttonsJsx}
          buttonsVariables={variablesButtons}
          disableClick
        />
      }

      </div>
    );

  });
  const firstCard = cardsJsx?.length === 2 && cardsJsx[0];
  const secondCard = cardsJsx?.length === 2 && cardsJsx[1];
  return (
    <CarouselCards>
      <div className="cards-container">
        {firstCard}
        {secondCard}
        <div className="arrow-left">
         {'<'}
        </div>
        <div className="arrow-right">
          {'>'}
        </div>
      </div>
    </CarouselCards>
  );
};




export const paintBody = (_content) => {
  const bodyObjectToReplace = getTemplateComponentFromContent(_content, 'BODY');
  const variablesToReplace = getVariablesFromContent(_content, 'body');
  const message = replacePlaceholders(bodyObjectToReplace, variablesToReplace);
  const regExpressionForStrong = /\*(.*?)\*/g;
  const regExpressionForEm = /\_(.*?)\_/g;
  const regExpressionForScratch = /\~(.*?)\~/g;

  let formattedHtml = message.replace(/\n/g, '<br>');

  const isBold = regExpressionForStrong.test(message);
  const isItalic = regExpressionForEm.test(message);
  const isScratched = regExpressionForScratch.test(message);

  if (isBold) {
    formattedHtml = formattedHtml.replace(regExpressionForStrong, "<strong>$1</strong>");
  }
  if (isItalic) {
    formattedHtml = formattedHtml.replace(regExpressionForEm, "<em>$1</em>");
  }
  if (isScratched) {
    formattedHtml = formattedHtml.replace(regExpressionForScratch, "<del>$1</del>");
  }

  return <div className="body-template" dangerouslySetInnerHTML={{ __html: formattedHtml }} />;
};
export const paintFooter = (_content) => {
  const footerObjectToReplace = getTemplateComponentFromContent(_content, 'FOOTER');
  if (!footerObjectToReplace) {
    return <div />;
  }

  let contentFooter;
  if (footerObjectToReplace?.text) {
    contentFooter = (
      <p className="footer-template">
        {footerObjectToReplace?.text}
      </p>
    );
  }
  const jsxFooter = (
    <div>
      {contentFooter}
    </div>
  );
  return jsxFooter;
};