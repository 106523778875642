import { createContext, useContext } from 'react';

// TODO: Context is currently mutable, should refactor to be inmutable.
const SocketContext = createContext({
  auth: { token: '', applicationId: '' },
  userInfo: {},
  conversations: [],
  supervisorAgentStatus: [],
  supervisorSubscribedConversations: [],
  auditorSubscribedConversations: [],
  conversationsAvailableAuditor: [],
  newMessages: [],
  selectedChatId: -1,
  isFetching: false,
  isMessageSent: false,
  socketError: {},
  stats: {},
  convTransferIds: [],
  scrollAllowed: { isAllowed: false, options: {} },
  resources: {},
  disconnected: false,
  setResources: () => {},
  setScrollAllowed: () => {},
  setSelectedChatId: () => {},
  setUnSelectedChat: () => {},
  insertOneMessage: () => {},
  insertHistoricalMessages: () => {},
  removeConversation: () => {},
  setIsMessageSent: () => {},
  agentStatus: '',
  setAgentStatus: () => {},
  formClose: null,
  setFormClose: () => {},
  setSupervisorAgentStatus: () => {},
  addSubscribedAuditorConv: () => {},
  addSubscribedSupervisorConv: () => {},
  removeSubscribedSupervisorConv: () => {},
  removeSubscribedAuditorConv: () => {},
  topics: [],
  setTopics: () => {},
  setConversations: () => {},
  retryTimers: [],
  setRetryTimers: () => {},
});

export default SocketContext;

const useSocketContext = () => useContext(SocketContext);

export { useSocketContext };
