import React from "react";
import PropTypes from 'prop-types';
import { Popover, CircularProgress } from "@mui/material";
import {
  StyledButton, SectionContainer, SectionTitle,
  SectionContent, CloseButton, MenuContainer
} from './styled';



const FloatingMenu = ({ 
  anchorEl, setAnchorEl, summary, suggestion, 
  i18n, isLoading, saveSuggestion, sources = [] 
}) => {

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickButton = () => {
    saveSuggestion(suggestion);
    handleClose();
  };

  const renderLinks = (links) => {
    if (!Array.isArray(links) || links.length === 0) {
      return <p>{i18n.drawer.notAvailableSources}</p>;
    }
    return (
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "floating-menu" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{ mt: -1.5 }}
    >
      <MenuContainer>
        <CloseButton onClick={handleClose} aria-label="Close">
          ✕
        </CloseButton>
        {isLoading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <SectionContainer>
              <SectionTitle>{i18n.drawer.summary}:</SectionTitle>
              <SectionContent>
                {summary || i18n.drawer.notAvailableSummary}
              </SectionContent>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{i18n.drawer.suggestedReply}:</SectionTitle>
              <SectionContent>
                {suggestion || i18n.drawer.notAvailableSuggestion}
              </SectionContent>
              <StyledButton onClick={handleClickButton} disabled={!suggestion}>
                {i18n.drawer.useSuggestion}
              </StyledButton>
            </SectionContainer>
            <SectionContainer noBorder>
              <SectionTitle>{i18n.drawer.sources}:</SectionTitle>
              <SectionContent>{renderLinks(sources)}</SectionContent>
            </SectionContainer>
          </>
        )}
      </MenuContainer>
    </Popover>
  );
};
FloatingMenu.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
  summary: PropTypes.string,
  suggestion: PropTypes.string,
  i18n: PropTypes.object,
  isLoading: PropTypes.bool,
  saveSuggestion: PropTypes.func.isRequired,
  sources: PropTypes.array,
};
export default FloatingMenu;
