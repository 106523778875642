/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { closeConversation, authenticateSocket, sendAgentStatus, supervisorSubscribeToConversation, supervisorUnsubscribeToConversation, auditorUnsubscribeToConversation } from './emit';
import { IO_EVENTS, MSG_SEND_TYPE, TRANFER_STATUS_MESSAGES, AGENT_STATUS, MSG_STATUS } from '../models';
import Paths from '../config/routes';
import appointedNotificationAudio from '../assets/audio/appointed.mp3';
import unconvincedNotificationAudio from '../assets/audio/unconvinced.mp3';
import { AudioService, AuthService, MessageStatusService, NotificationService } from '../services';
import * as api from '../api';
import i18n from '../assets/i18n';
import { removeMessageFromDB } from '../db/dbUtils'

class SocketEventManager {
  constructor() {
    if (SocketEventManager.instance) {
      return SocketEventManager.instance;
    }
    SocketEventManager.instance = this;
    return this;
  }
  init = (setGlobalState, removeConversation, setScrollAllowed, history, socket, insertOneMessage, insertHistoricalMessages) => {
    AudioService.setAudioSource(appointedNotificationAudio)
    AudioService.play()

    NotificationService.requestPermission();

    this.setGlobalState = setGlobalState;
    this.removeConversation = removeConversation;
    this.setScrollAllowed = setScrollAllowed;
    this.history = history;
    this.socket = socket;
    this.agentStatus = AGENT_STATUS.HALTING;
    this.insertOneMessage = insertOneMessage;
    this.insertHistoricalMessages = insertHistoricalMessages;
    this.showingOkTransferToast = false;
  };

  static shared = () => new SocketEventManager();

  onMessageReceived = (data) => {
    console.log('onMessageReceived: ', data);
    const scrollOptions = {};
    if ('conversations' in data) {
      this.setGlobalState((state) => {
        if (!_.isEmpty(state.conversations)) return { ...state };
        const newMessages = [];
        const conversationsWithSortedMessages = data?.conversations?.map((conv) => {
          const uniqueMessages = _.uniqBy(conv.messages, 'messageId');
          const sortedMessages = _.orderBy(uniqueMessages, ['timespan', 'messageId'], ['asc', 'asc']);
          const auxSorted = { ...conv };
          auxSorted.messages = sortedMessages;
          const userMessages = _.filter(sortedMessages, (mes) => mes.sendType === MSG_SEND_TYPE.INPUT);
          newMessages.push({ convId: conv.conversationSessionId, count: userMessages.length });
          return auxSorted;
        });
        const thereAreConvs = (state.conversations && state?.conversations?.length > 0) || (data.conversations && data?.conversations?.length > 0);
        // eslint-disable-next-line consistent-return
        const unread = thereAreConvs && document.hidden ? state.countUnread + 1 : 0;
        return { ...state, conversations: conversationsWithSortedMessages, newMessages, countUnread: unread };
      });
    } else { // receive one conversation
      this.setGlobalState((state) => {
        const { conversations, newMessages, selectedChatId } = state;
        const aux = [...conversations];
        const auxNewMessages = [...newMessages];

        if (_.find(conversations, (conv) => conv.conversationSessionId === data.conversationSessionId)) {
          // Conversacion existente
          const index = _.findIndex(aux, (conv) => conv.conversationSessionId === data.conversationSessionId);
          
          /*   if (data.history) {
              this.insertHistoricalMessages(aux[index].conversationSessionId, data.messages);
            } else { */

          data.messages.forEach((message) => {
            if (!_.find(aux[index].messages, (mes) => mes && mes.messageId === message.messageId)) {
              aux[index].messages.push(message);
            }
          });
          if (data?.topics?.length) {
            aux[index].topics = data.topics;
          }
           /*  } */

            const indexInNewMessages = _.findIndex(auxNewMessages, (conv) => conv.convId === data.conversationSessionId);

            if (selectedChatId !== auxNewMessages[indexInNewMessages].convId) {
              auxNewMessages[indexInNewMessages].count += 1;
            }
            AudioService.setAudioSource(appointedNotificationAudio);
            scrollOptions.behavior = 'smooth';
          /* } */
        } else {
          // insertando una nueva conversacion
          aux.push(data);

          const userMessages = _.filter(data.messages, (mes) => mes.sendType === MSG_SEND_TYPE.INPUT);

          const newMessagesObj = { convId: data.conversationSessionId, count: userMessages.length };
          auxNewMessages.push(newMessagesObj);

          AudioService.setAudioSource(unconvincedNotificationAudio);
        }

        const conversationsWithSortedMessages = aux.map((conv) => {
          const uniqueMessages = _.uniqBy(conv.messages, 'messageId');
          const sortedMessages = _.orderBy(uniqueMessages, ['timespan', 'messageId'], ['asc', 'asc']);

          const auxSorted = { ...conv };
          auxSorted.messages = sortedMessages;
          return auxSorted;
        });
        // Reproduciendo sonido si no se esta en la conv selec
        if (selectedChatId !== data.conversationSessionId || document.hidden) {
          AudioService.play();
        }
        if (selectedChatId === data.conversationSessionId) {
          if (conversationsWithSortedMessages.length) {
            if (!data.history) {
              this.setScrollAllowed(true, scrollOptions);
            }

          } else {
            this.setScrollAllowed(true, scrollOptions);
          }

          
        }
        const unread = document.hidden ? state.countUnread + 1 : 0;
        return { ...state, conversations: conversationsWithSortedMessages, newMessages: auxNewMessages, countUnread: unread };
      });
    }
  };

  onStatsAgentsReceived = (stats) => {
    this.setGlobalState((state) => {
      return { ...state, stats };
    });
  };

  onSendMessageResultReceived = (data) => {
    const { messages, conversationSessionId, agentMessageId } = data;

    if (messages && messages.length > 0) {
        removeMessageFromDB(agentMessageId);

        this.setGlobalState((state) => {
            const retryTimers = [...state.retryTimers];
            const timerIndex = retryTimers.findIndex(timer => timer.agentMessageId === agentMessageId);

            if (timerIndex !== -1) {
                clearTimeout(retryTimers[timerIndex].timeoutId);
                retryTimers.splice(timerIndex, 1);
            }

            return {
                ...state,
                isMessageSent: true,
                retryTimers
            };
        });

        this.insertOneMessage(conversationSessionId, messages[0]);
    } else {
        toast(i18n.chats.messageNotDelivered, { type: toast.TYPE.ERROR });
    }
};


  onForm = (data) => {
    this.setGlobalState((state) => {
      return { ...state, formClose: data };
    });
  };

  onAgentStatusReceived = async (data) => {
    try {
      this.setGlobalState((state) => {
        const { agents, users } = state;
        const copyAgents = cloneDeep(agents);
        // if there are new agents
        data.forEach((el) => {
          const indexAgent = copyAgents.findIndex((e) => e.agentId === el.agentId);
          if (indexAgent > -1) {
            // agent is already in the list
            const agent = copyAgents[indexAgent];
            copyAgents[indexAgent] = { ...agent, ...el };
          } else {
            // add required user info
            const agentFiltered = users.filter((e) => e.id === el.webUserId);
            if (agentFiltered.length && agentFiltered.length > 0) {
              copyAgents.push({ ...el, name: agentFiltered[0].name, email: agentFiltered[0].email, userImage: agentFiltered[0].userImage });
            }
          }
        });
        return { ...state, agents: copyAgents };
      });
    } catch (error) {
      toast(i18n.toast.errorLoadUsers, {
        type: toast.TYPE.ERROR,
      });
    }
  };

  onMessageStatusReceived = (data) => {
    console.log('onMessageStatusReceived: ', data);
    if (data.status === MSG_STATUS.CHANNEL_SENT.VALUE || data.status === MSG_STATUS.CHANNEL_DELIVERED.VALUE) {
      this.setGlobalState((state) => {
        return { ...state, isMessageSent: true };
      });
    }

    const { status, messageId, conversationSessionId } = data;

    this.setGlobalState((state) => {
      const { conversations, selectedChatId } = state;
      const auxConversations = conversations?.map((conv) => {
        if (conv.conversationSessionId === conversationSessionId) {
          const auxMessages = conv.messages.map((message) => {
            if (message && message.messageId === messageId) {
              const prioritaryStatus = MessageStatusService.checkPriorityStatus(message.status, status);
              return { ...message, status: prioritaryStatus };
            }

            return message;
          });
          conv.messages = auxMessages;
        }
        return conv;
      });

      if (selectedChatId === conversationSessionId) {
        this.setScrollAllowed(true, { behavior: 'smooth' });
      }
      return { ...state, conversations: auxConversations };
    });
  };

  onUnauthorizedReceived = async (msg) => {
    this.setGlobalState((state) => {
      return { ...state, socketError: { message: msg, event: IO_EVENTS.ON.UNAUTHORIZED } };
    });
    try {
      await AuthService.doAuth();
      const waToken = AuthService.getWaToken();
      authenticateSocket(waToken);
    } catch (error) {
      api.removeAxiosToken();
      this.history.push(Paths.ERROR);
    }
  };

  notify = () => {
    //toast(i18n.errors.socketDisconnected, { type: toast.TYPE.ERROR, autoClose: false, toastId: 99 });
  };

  dismiss = () => {
    toast.dismiss(99);
  };

  onConnectReceived = () => {
    // eslint-disable-next-line no-console
    console.log('ON CONNECT');
    const waToken = AuthService.getWaToken();
    const admToken = AuthService.getAdminToken();
    api.tokenToAxiosHeader(admToken);
    authenticateSocket(waToken);
    this.dismiss();
    this.setGlobalState((state) => {
      return { ...state, disconnected: false, socketError: {} };
    });
  };

  onDisconnectReceived = (reason) => {
    // eslint-disable-next-line no-console
    console.log('ON DISCONNECT : reason : ', reason);
    setTimeout(() => {
      if (this.socket.disconnected) {
        this.notify();
      }
    }, 10000);
    this.setGlobalState((state) => {
      // save agent status before reconnection
      const { agents, userInfo, disconnected } = state;
      const copyAgents = cloneDeep(agents);
      const indexAgent = copyAgents.findIndex((e) => e.agentId === userInfo.agentId);
      if (indexAgent !== -1) {
        const agent = copyAgents[indexAgent];
        const previousStatus = agent ? agent.status : AGENT_STATUS.HALTING;
        copyAgents[indexAgent].previousStatus = previousStatus;
      }

      return { ...state, disconnected: true, agents: copyAgents, socketError: { message: reason, event: IO_EVENTS.ON.DISCONNECT } };
    });
  };

  onAuthenticatedReceived = (data) => {
    const token = AuthService.getWaToken();
    const applicationId = AuthService.getApplicationId();
    this.setGlobalState((state) => {
      const agent = _.find(state.agents, (val) => val.agentId === state.userInfo.agentId);
      this.previousStatus = agent ? agent.previousStatus : AGENT_STATUS.HALTING;
      const auth = { ...state.auth, token, applicationId };
      const crmIdentifyEnabled = data && data.crmIdentifyEnabled;
      const crmExportConversationEnabled = data && data.crmExportConversationEnabled;
      const crmCaseValues = data && data.crmCaseValues;
      const convSubscriptions = state.supervisorSubscribedConversations;

      if (!_.isEmpty(convSubscriptions)) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < convSubscriptions.length; i++) {
          supervisorSubscribeToConversation(convSubscriptions[i]);
        }
      }

      return { ...state, auth, socketError: {}, crmIdentifyEnabled, crmExportConversationEnabled, crmCaseValues };
    });

    // recover agent status before reconnected
    if (this.previousStatus && this.previousStatus === AGENT_STATUS.AVAILABLE) sendAgentStatus(this.previousStatus);
  };

  onAgentTransferStatusReceived = (msg) => {
    const { conversationSessionId, status, message } = msg;

    if (message === TRANFER_STATUS_MESSAGES.AGENT_TRANSFER_START) {
      this.setGlobalState((state) => {
        const { convTransferIds } = state;
        convTransferIds.push(conversationSessionId);

        return { ...state, selectedChatId: -1, convTransferIds };
      });
    }
    if (message === TRANFER_STATUS_MESSAGES.AGENT_TRANSFER_ERROR || status === TRANFER_STATUS_MESSAGES.AGENT_TRANSFER_NO_AGENTS) {
      this.setGlobalState((state) => {
        const convTransferIds = state.convTransferIds.filter((conv) => conv !== conversationSessionId);

        return { ...state, convTransferIds };
      });
      toast(i18n.toast.errorTransfer, {
        type: toast.TYPE.ERROR,
        onClose: () => {
          this.removeConversation(conversationSessionId);
          closeConversation(conversationSessionId);
        },
      });
    }
    if (status === TRANFER_STATUS_MESSAGES.AGENT_TRANSFER_SAME_AGENT || message === TRANFER_STATUS_MESSAGES.AGENT_TRANSFER_SAME_AGENT) {
      this.setGlobalState((state) => {
        const convTransferIds = state.convTransferIds.filter((conv) => conv !== conversationSessionId);

        return { ...state, convTransferIds };
      });
      toast(i18n.toast.sameAgentAfterTransfer, {
        type: toast.TYPE.INFO,
      });
    }
    if (status === TRANFER_STATUS_MESSAGES.AGENT_TRANSFER_DONE) {
      this.setGlobalState((state) => {
        const convTransferIds = state.convTransferIds.filter((conv) => conv !== conversationSessionId);

        return { ...state, convTransferIds };
      });
      if (!this.showingOkTransferToast) {
        toast(i18n.toast.correctConvTransfer, {
          type: toast.TYPE.SUCCESS,
          onOpen: () => {
            this.showingOkTransferToast = true;
          },
          onClose: () => {
            this.showingOkTransferToast = false;
          },
        });
      }
    }
  };

  onSupervisorAgentStatusReceived = (stats) => {
    // All agent status for supervisor incoming
    this.setGlobalState((state) => {
      const { users, supervisorAgentStatus = [] } = state;
      const newSupervisorAgentStatus = _.cloneDeep(supervisorAgentStatus);
      // removing from the stats socket message the users that are not in the state users array
      const filteredStats = stats.filter((stat) => {
        let isStatInUsersList = false;
        users.forEach((user) => {
          if (user.id === stat.webUserId) {
            isStatInUsersList = user.id === stat.webUserId;
          }
        });
        return isStatInUsersList;
      });

      filteredStats.forEach((agentStats) => {
        const { conversations = [], ...agentStatsWithoutConvs } = agentStats;
        const { webUserId, agentId } = agentStatsWithoutConvs;

        // update agent status but conversations
        const newAgentStats = agentStatsWithoutConvs;
        newAgentStats.conversations = [];

        // update API user profile info
        const user = _.find(users, (userData) => {
          return userData.id === webUserId;
        });
        if (user) {
          newAgentStats.name = user.name;
          newAgentStats.userImage = user.userImage;
        } else {
          agentStats.name = i18n.formatString(i18n.supervisor.agent, { agentId });
        }

        // update agent
        const currentAgentStatusIndex = supervisorAgentStatus.findIndex((agentStatus) => agentStatus.agentId === agentId);
        if (currentAgentStatusIndex !== -1) {
          // if agent status is already in the state, update it
          const currentAgentConversations = (supervisorAgentStatus[currentAgentStatusIndex] || {}).conversations || [];
          newAgentStats.conversations = currentAgentConversations;
          if (conversations && conversations?.length) {
            conversations?.forEach((conv) => {
              const currentConversationIndex = currentAgentConversations?.findIndex((c) => c.agentSessionId === conv.agentSessionId);
              if (currentConversationIndex !== -1) {
                newAgentStats[currentConversationIndex] = conv;
              } else {
                newAgentStats.conversations?.push(conv);
              }
            });
          }
          newSupervisorAgentStatus[currentAgentStatusIndex] = newAgentStats;
        } else {
          newAgentStats.conversations = conversations || [];
          newSupervisorAgentStatus.push(newAgentStats);
        }
      });

      return { ...state, supervisorAgentStatus: newSupervisorAgentStatus };
    });
  };

  onConversationClosed = (data) => {
    const { conversationSessionId, agentSessionId } = data;
    this.removeConversation(conversationSessionId);
    this.setGlobalState((state) => {
      const { supervisorAgentStatus, supervisorSubscribedConversations, auditorSubscribedConversations } = state;
      let subscriptions = [];
      if (_.find(supervisorSubscribedConversations, (sub) => conversationSessionId === sub)) {
        subscriptions = supervisorSubscribedConversations?.filter((sub) => sub !== conversationSessionId);
        supervisorUnsubscribeToConversation(conversationSessionId);
      }
      if (_.find(auditorSubscribedConversations, (sub) => conversationSessionId === sub)) {
        subscriptions = auditorSubscribedConversations?.filter((sub) => sub !== conversationSessionId);
        auditorUnsubscribeToConversation(conversationSessionId);
      }

      const updatedStats = _.map(supervisorAgentStatus, (agentStats) => {
        const { conversations } = agentStats;
        const filteredConvs = conversations?.filter((conv) => conv.agentSessionId !== agentSessionId);
        agentStats.conversations = filteredConvs;
        return agentStats;
      });

      return { ...state, supervisorAgentStatus: updatedStats, supervisorSubscribedConversations: subscriptions };
    });
  };
}

export default SocketEventManager;
