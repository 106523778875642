/* eslint-disable consistent-return */
import React from 'react';
import { MSG_CONTENT_TYPES } from '../../../models';
import { paintBody, paintHeader } from '../template-message-bubble/utils';


export const mergeAndSortByTimespan = (array1, array2) => {
  const combinedArray = [...array1, ...array2];
  combinedArray.sort((a, b) => a.timespan - b.timespan);
  
  return combinedArray;
}

export const getLabelInteractiveList = (linkedMessage, payloadMessage) => {
  let labelToReturn;
  if (linkedMessage?.contentType === MSG_CONTENT_TYPES.INTERACTIVE) {
    if (linkedMessage?.content?.interactive?.type === 'list') {
      const sections = linkedMessage?.content?.interactive?.action?.sections;
      let title;
      sections?.forEach(section => {
        section.rows?.forEach(row => {
          if (row.payload === payloadMessage) {
            title = row.title;
            labelToReturn = title;
          }
        });
      });
    }
  }
  return labelToReturn;
}
export const getLabelInteractiveButton = (linkedMessage, payloadMessage) => {
  let labelToReturn;
  const buttons = linkedMessage?.content?.interactive?.action?.buttons;
  let title;
  buttons?.forEach((button) => {
    if (button.reply?.payload === payloadMessage) {
      title = button.reply.title;
      labelToReturn = title;
    }
  });
  return labelToReturn;
}
// parseo infumable para poder conseguir el label a partir del eventName que viene en el mensaje quickReply para template.
export const getLabelTemplate = (linkedMessage, payloadMessage) => {
  
  // Check if theres carousel in components
  const carouselTemplate = linkedMessage?.content?.templateComponents?.find(el => el?.type === 'CAROUSEL');
  if (carouselTemplate) {
    const carouselComponent = linkedMessage?.content?.components?.find(el => el?.type === 'carousel');
    if (!carouselComponent) return null;
    
    let cardPosition = null;
    let buttonPosition = null;
    
    carouselComponent.cards.forEach((card, cardIndex) => {
      card.components.forEach((component) => {
        if (component.type === 'button' && component.subType === 'quickReply') {
          const payloadMatch = component.parameters?.some(param => param?.payload === payloadMessage);
          if (payloadMatch) {
            cardPosition = cardIndex;
            buttonPosition = parseInt(component.index, 10);
          }
        }
      });
    });
    
    if (cardPosition === null || buttonPosition === null) return null;
    
    // card position
    const templateCard = carouselTemplate.cards?.[cardPosition];
    if (!templateCard) return null;
    
    // button position
    const buttonsComponent = templateCard.components?.find(c => c.type === 'BUTTONS');
    if (!buttonsComponent || !buttonsComponent.buttons?.[buttonPosition]) return null;
    
    return buttonsComponent.buttons[buttonPosition].text;
  }

  // templates that are not CAROUSEL
  const buttonsComponents = linkedMessage?.content?.components?.filter((el) => el?.type === 'button');
  const buttonsTemplateComponents = linkedMessage?.content?.templateComponents?.find(el => el?.type === 'BUTTONS');
  
  const theButton = buttonsComponents?.find((el) => 
    el?.parameters?.some(param => param?.payload === payloadMessage)
  );
  
  const indexButtonToFindLabel = theButton?.index && parseInt(theButton.index, 10);
  
  return (indexButtonToFindLabel !== null && indexButtonToFindLabel !== undefined
    && buttonsTemplateComponents?.buttons?.length
    && buttonsTemplateComponents?.buttons[indexButtonToFindLabel]?.text) || null;
};


export const getJsxLinkedMessage = (linkedMessage) => {
  const isLinkedTemplateMessage = linkedMessage?.contentType === MSG_CONTENT_TYPES.TEMPLATE;
  const isLinkedEventFlowMessage = linkedMessage?.contentType === MSG_CONTENT_TYPES.EVENT;
  const isLinkedInteractiveMessage = linkedMessage?.contentType === MSG_CONTENT_TYPES.INTERACTIVE;
  if (isLinkedTemplateMessage) {
    const jsxToReturn = (
      <>
      {paintHeader(linkedMessage.content)}
      {paintBody(linkedMessage.content)}
      </>
    );
    return jsxToReturn;

  }
  if (isLinkedEventFlowMessage) {
    return ( 
      null
    )
  }
  if (isLinkedInteractiveMessage) {
    const interactive = linkedMessage?.content?.interactive;
    const jsxToReturn = (
    <>
    {interactive?.header?.text ? <div className="interactive-header-wrapper">
      <p className="interactive-header-text">{interactive?.header?.text}</p>
    </div> : <div/>}
    {interactive?.body?.text ? <div className="interactive-body-wrapper">
      <p className="interactive-body-text">{interactive?.body?.text}</p>
    </div> : <div/>}
    </>)
    return jsxToReturn;
  }
  return null;
} 