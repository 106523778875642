import styled from '@emotion/styled';

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-size: 18px;
  gap: 8px;
`;

export const WarningText = styled.span`
  color: red;
`;