/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types'; 
import EventFlowMessageBubble from '../event-flow-message-bubble';
import TextMessageBubble from '../text-message-bubble';
import TemplateMessageBubble from '../template-message-bubble';
import MultimediaMessageBubble from '../multimedia-message-bubble';
import QuickReplyMessageBubble from '../quickReply-message-bubble';
import { withI18n } from '../../../hocs';
import { MSG_CONTENT_TYPES, MSG_SEND_TYPE, MSG_STATUS } from '../../../models';
import { MessagesListStyled } from './styled';
import { MessageStatusService } from '../../../services';
import { getLabelInteractiveList,
  getLabelInteractiveButton, getLabelTemplate, getJsxLinkedMessage,
  mergeAndSortByTimespan } from './utils';

const getMessageFromContentType = (contentType, content, i18n) => {
  let message = '';
  switch (contentType) {
    case MSG_CONTENT_TYPES.TEXT:
      message = content.body;
      break;
    case MSG_CONTENT_TYPES.IMAGE:
      message = content.caption;
      break;
    case MSG_CONTENT_TYPES.VIDEO:
      message = content.caption;
      break;
    case MSG_CONTENT_TYPES.DOCUMENT:
      message = content.caption;
      break;
    case MSG_CONTENT_TYPES.STICKER:
    case MSG_CONTENT_TYPES.VOICE:
    case MSG_CONTENT_TYPES.AUDIO:
    case MSG_CONTENT_TYPES.LOCATION:
    case MSG_CONTENT_TYPES.CONTACTS:
      break;
    default:
      message = i18n.chats.notAbleToShowMessage;
  }
  return message;
};

const getColorClassFromSendType = (messageSendType) => {
  let colorClass = '';
  switch (messageSendType) {
    case MSG_SEND_TYPE.OPERATOR:
      colorClass = 'message-bubble-shape-operator message-bubble-color-operator';
      break;
    case MSG_SEND_TYPE.PROACTIVE:
      colorClass = 'message-bubble-shape-proactive message-bubble-color-bot';
      break;
    case MSG_SEND_TYPE.INPUT:
      colorClass = 'message-bubble-shape message-bubble-color-input';
      break;
    default:
      colorClass = 'message-bubble-shape-bot message-bubble-color-bot';
  }
  return colorClass;
};

// TODO: Refactor Message List to have a MessageItem.
// TODO: Message Item will render a Bubble depending on teh type: Text or Multimedia
// TODO: Extract Message  Text Bubble for wrapping the three kind of possible text message: ok, deleted or unknown
// TODO: Extract Multimedia Bubble to a common wrapper for each kind of multimedia type bubble
const MessagesList = ({ chat, i18n, formatDateString, onMultimediaClick, messagesPending, }) => {
  const cleanNoMessagesIds = chat.messages.filter(element => element?.messageId);
  const messagesWithPending = mergeAndSortByTimespan(cleanNoMessagesIds || [], messagesPending || []);
  const list = messagesWithPending.map((mes, index) => {
    const { contentType, messageId, sendType, type, timespan, content = {}, status } = mes || {};
    const message = getMessageFromContentType(contentType, content, i18n);
    const multiMediaResource = content.multimediaUrl;
    const isTextMessage = contentType === MSG_CONTENT_TYPES.TEXT;
    const isImageMessage = contentType === MSG_CONTENT_TYPES.IMAGE;
    const isVideoMessage = contentType === MSG_CONTENT_TYPES.VIDEO;
    const isStickerMessage = contentType === MSG_CONTENT_TYPES.STICKER;
    const isContactsMessage = contentType === MSG_CONTENT_TYPES.CONTACTS;
    const isLocationMessage = contentType === MSG_CONTENT_TYPES.LOCATION;
    const isDocumentMessage = contentType === MSG_CONTENT_TYPES.DOCUMENT;
    const isVoiceMessage = contentType === MSG_CONTENT_TYPES.VOICE;
    const isAudioMessage = contentType === MSG_CONTENT_TYPES.AUDIO;
    const isVoiceOrAudioMessage = isVoiceMessage || isAudioMessage;
    const isInteractiveMessage = contentType === MSG_CONTENT_TYPES.INTERACTIVE;
    const isTemplateMessage = contentType === MSG_CONTENT_TYPES.TEMPLATE;
    const isQuickReplyMessage = contentType === MSG_CONTENT_TYPES.QUICK_REPLY;
    const isUnknownMessage = contentType === MSG_CONTENT_TYPES.UNKNOWN;
    const isEventFlowMessage = contentType === MSG_CONTENT_TYPES.EVENT;
    let eventFlowLinkedMessageToPaint;
    let resourceId;
    if (content && content.id) resourceId = content.id;
    const messageSendType = sendType || type;
    const colorClass = getColorClassFromSendType(messageSendType);
    const date = moment(timespan).format(formatDateString);
    const key = messageId || `MessagesList${index}`;
    const { channel } = chat;
    let { statusIcon } = channel !== 'messenger' && MessageStatusService.getStatusIcon(status, channel);
    const { deletedIcon, errorIcon } = channel !== 'messenger' && MessageStatusService.getStatusIcon(status, channel);
    if (!mes.messageId) {
      statusIcon = MessageStatusService.getStatusIcon(MSG_STATUS.TRYING_AGAIN.VALUE).statusIcon;
    }
    
    let quickReplyLinkedMessage;
    let labelQuickReply;
    if (isQuickReplyMessage && mes?.content?.messageId) {

        const linkedMessage = cleanNoMessagesIds?.find(el => el?.messageId === mes?.content?.messageId);

        const payloadMessage = mes?.content?.eventName;
        
        if (linkedMessage?.contentType === MSG_CONTENT_TYPES.TEMPLATE) {
          labelQuickReply = getLabelTemplate(linkedMessage, payloadMessage);
        }
        if (linkedMessage?.contentType === MSG_CONTENT_TYPES.INTERACTIVE) {
          if (linkedMessage?.content?.interactive?.type === 'list') {
            labelQuickReply = getLabelInteractiveList(linkedMessage, payloadMessage);
          }
          if (linkedMessage?.content?.interactive?.type === 'button') {
            labelQuickReply = getLabelInteractiveButton(linkedMessage, payloadMessage);
          }
        }
        if (linkedMessage) {
          quickReplyLinkedMessage = getJsxLinkedMessage(linkedMessage)
        }
    
    }
    if (isEventFlowMessage) {
      const linkedMessageId = mes?.content?.eventParameters?.flowResponse?.flowToken?.flowMessageId
      const linkedMessage = (typeof linkedMessageId === 'number') && cleanNoMessagesIds?.find(el => el?.messageId === linkedMessageId);
      eventFlowLinkedMessageToPaint = getJsxLinkedMessage(linkedMessage);
      
    }

    return (
      <div id={`msg-${messageId}`} key={key}>
        {deletedIcon ? (
          <TextMessageBubble
            date={null}
            message={i18n.chats.messageWasDeleted}
            colorClass={colorClass}
            sendType={sendType}
            statusIcon={null}
            deletedIcon={deletedIcon}
          />
        ) : (
          <>
            {isTextMessage && (
              <TextMessageBubble
                date={date}
                message={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                deletedIcon={deletedIcon}
                errorIcon={errorIcon}
              />
            )}
            {isTemplateMessage && (
              <TemplateMessageBubble
                date={date}
                content={content}
                message={message}
                colorClass={colorClass}
                sendType='operator'
                statusIcon={statusIcon}
                deletedIcon={deletedIcon}
                onMultimediaClick={onMultimediaClick}
                errorIcon={errorIcon}
              />
            )}
            {isEventFlowMessage && (
              <EventFlowMessageBubble
                date={date}
                content={content}
                colorClass={colorClass}
                sendType='operator'
                statusIcon={statusIcon}
                deletedIcon={deletedIcon}
                onMultimediaClick={onMultimediaClick}
                errorIcon={errorIcon}
                linkedMessage={eventFlowLinkedMessageToPaint}
              />
            )}
            {isQuickReplyMessage && <QuickReplyMessageBubble
                date={date}
                message={content && content.eventName ? content.eventName : message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                deletedIcon={deletedIcon}
                linkedMessage={quickReplyLinkedMessage}
                label={labelQuickReply}
                errorIcon={errorIcon}
              />}
            {isUnknownMessage && <TextMessageBubble date={date} message={message} colorClass={colorClass} sendType={sendType} />}
            {isDocumentMessage && (
              <MultimediaMessageBubble
                document
                id={resourceId}
                resourceURL={multiMediaResource}
                date={date}
                caption={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isStickerMessage && (
              <MultimediaMessageBubble
                sticker
                onMultimediaClick={onMultimediaClick}
                id={resourceId}
                resourceURL={multiMediaResource}
                date={date}
                caption={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isContactsMessage && (
              <MultimediaMessageBubble
                contacts
                onMultimediaClick={onMultimediaClick}
                content={content[0]}
                resourceURL={multiMediaResource}
                date={date}
                caption={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isLocationMessage && (
              <MultimediaMessageBubble
                location
                onMultimediaClick={onMultimediaClick}
                content={content}
                resourceURL={multiMediaResource}
                date={date}
                caption={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isVideoMessage && (
              <MultimediaMessageBubble
                video
                onMultimediaClick={onMultimediaClick}
                id={resourceId}
                resourceURL={multiMediaResource}
                date={date}
                caption={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isImageMessage && (
              <MultimediaMessageBubble
                image
                onMultimediaClick={onMultimediaClick}
                id={resourceId}
                resourceURL={multiMediaResource}
                date={date}
                caption={message}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isVoiceOrAudioMessage && (
              <MultimediaMessageBubble
                audio
                id={resourceId}
                resourceURL={multiMediaResource}
                date={date}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
            {isInteractiveMessage && (
              <MultimediaMessageBubble
                interactive
                id={resourceId}
                resourceURL={multiMediaResource}
                date={date}
                content={content}
                colorClass={colorClass}
                sendType={sendType}
                statusIcon={statusIcon}
                errorIcon={errorIcon}
              />
            )}
          </>
        )}
      </div>
    );
  });

  return <MessagesListStyled>{list}</MessagesListStyled>;
}

MessagesList.propTypes = {
  chat: PropTypes.object,
  formatDateString: PropTypes.string,
  onMultimediaClick: PropTypes.func,
  i18n: PropTypes.object,
  messagesPending: PropTypes.array,
};

export default withI18n(MessagesList);
