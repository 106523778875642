

const dbChannel = new BroadcastChannel('db_updates');

export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('chatDatabase', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      
      if (!db.objectStoreNames.contains('messages')) {
        const store = db.createObjectStore('messages', { keyPath: 'agentMessageId' });
        store.createIndex('status', 'status', { unique: false });
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const getMessagesFromDB = async () => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['messages'], 'readonly');
    const store = transaction.objectStore('messages');
    const request = store.getAll();

    request.onerror = () => reject('Error reading messages from DB');
    request.onsuccess = () => resolve(request.result);
  });
};

export const addMessageToDB = async (message) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['messages'], 'readwrite');
    const store = transaction.objectStore('messages');
    const request = store.put(message);

    request.onsuccess = () => {
      dbChannel.postMessage({ type: 'update' });
      resolve();
    };
    request.onerror = () => reject('Error adding message to DB');
  });
};
export const removeMessageFromDB = async (agentMessageId) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['messages'], 'readwrite');
    const store = transaction.objectStore('messages');
    const request = store.delete(agentMessageId);

    request.onsuccess = () => {
      dbChannel.postMessage({ type: 'update' });
      resolve();
    };
    request.onerror = () => reject('Error removing message from DB');
  });
};

export const getAllBufferMessagesFromDB = async () => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['messages'], 'readonly');
    const store = transaction.objectStore('messages');
    const getAllRequest = store.getAll();

    getAllRequest.onsuccess = () => {
      resolve(getAllRequest.result);
    };

    getAllRequest.onerror = (event) => {
      console.error('Error al obtener mensajes de IndexedDB', event.target.error);
      reject(event.target.error);
    };
  });
};
export const watchDBChanges = (callback) => {
  const dbRequest = indexedDB.open('chatDatabase');

  dbRequest.onsuccess = (event) => {
    const db = event.target.result;

    const transaction = db.transaction("messages", "readonly");
    const store = transaction.objectStore("messages");

    const getAllRequest = store.getAll();
    getAllRequest.onsuccess = () => {
      const messages = getAllRequest.result;
      console.log("Mensajes en la base de datos:", messages);  // Verify stored messages
    };

    store.openCursor().onsuccess = (cursorEvent) => {
      const cursor = cursorEvent.target.result;
      if (cursor) {
        console.log("Mensaje del cursor:", cursor.value);  // Verify value of message
        callback();
        cursor.continue();
      } else {
        console.log("No hay más mensajes.");  // Verify if cursor is done
      }
    };
  };

  dbRequest.onerror = (event) => {
    console.error("Error al abrir la base de datos:", event.target.error);
  };

  return () => {
    dbRequest.result?.close();
  };
};




export const clearBufferMessagesDB = async () => {
  const db = await openDatabase();
  dbChannel.postMessage({ type: 'cleared' });
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['messages'], 'readwrite');
    const store = transaction.objectStore('messages');
    store.clear();
    resolve();
  });
};
