import { useEffect, useRef } from "react";
import { removeMessageFromDB, addMessageToDB } from "../db/dbUtils";
import { useBufferMessages } from "./useBufferMessages"; // Hook para leer mensajes pendientes

const MAX_RETRIES = 4;
const RETRY_DELAYS = [5000, 8000, 16000, 32000];

const useMessageRetry = (sendMessage, setRetryTimers) => {
  const messages = useBufferMessages();
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    console.log("Mensajes pendientes en el buffer:", messages);
    if (messages.length === 0) return;

    let isMounted = true; // Para evitar ejecutar acciones si se desmonta el componente

    const retryNextMessage = async () => {
      // Ordenamos los mensajes por timestamp (el más antiguo primero)
      const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);
      if (!sortedMessages.length || !isMounted) return;
      const firstMessage = sortedMessages[0];

      // Si se alcanzó el máximo de reintentos, eliminamos el mensaje y salimos
      if (firstMessage.retries >= MAX_RETRIES) {
        console.log(`Mensaje ${firstMessage.agentMessageId} alcanzó el máximo de reintentos.`);
        await removeMessageFromDB(firstMessage.agentMessageId);
        return;
      }

      // Incrementamos el contador de reintentos
      const updatedMessage = { ...firstMessage, retries: (firstMessage.retries || 0) + 1 };
      console.log("Reintentando mensaje (actualizado):", updatedMessage);

      const delay = RETRY_DELAYS[updatedMessage.retries - 1] || RETRY_DELAYS[RETRY_DELAYS.length - 1];
      console.log(`Programado reintento de ${updatedMessage.agentMessageId} en ${delay / 1000} segundos`);

      // Programamos el reintento con setTimeout
      timeoutIdRef.current = setTimeout(async () => {
        try {
          console.log(
            `Reintentando mensaje ${updatedMessage.agentMessageId}, intento #${updatedMessage.retries}`
          );
          await sendMessage(updatedMessage);
        } catch (error) {
          console.error(`Error enviando mensaje ${updatedMessage.agentMessageId}:`, error);
        } finally {
          // Actualizamos la base de datos con el número de reintentos,
          // independientemente de si el envío fue exitoso o no.
          try {
            await addMessageToDB(updatedMessage);
          } catch (dbError) {
            console.error("Error actualizando mensaje en la base de datos:", dbError);
          }

          // Eliminamos el timer registrado (opcional, si se usa para limpiar la UI o control)
          setRetryTimers(prev =>
            prev.filter(timer => timer.agentMessageId !== updatedMessage.agentMessageId)
          );

          // Llamamos de forma recursiva para volver a intentar (si el mensaje sigue en el buffer)
          if (isMounted) {
            retryNextMessage();
          }
        }
      }, delay);

      // Registramos el temporizador (opcional)
      setRetryTimers(prev => [...(prev ?? []), { agentMessageId: updatedMessage.agentMessageId, timeoutId: timeoutIdRef.current }]);
    };

    console.log("Iniciando ciclo de reintentos");
    retryNextMessage();

    // eslint-disable-next-line consistent-return
    return () => {
      isMounted = false;
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [messages, sendMessage, setRetryTimers]);

  return null;
};

export default useMessageRetry;
