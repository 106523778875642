export default {
  auth: {
    loading: 'Carregando...',
    waContCent: 'Centro de Controle WhatsApp',
  },
  errors: {
    unauthorized: 'Você não tem permissões suficientes para acessar a ferramenta. Se for um erro, entre em contato com o administrador da sua conta ou com o suporte da Indigitall.',
    goBackToIndigitall: 'Voltar para Indigitall',
    imageFileNotAvailable: 'A imagem não está disponível',
    videoFileNotAvailable: 'O vídeo não está disponível',
    locationFileNotAvailable: 'O mapa não está disponível',
    documentFileNotAvailable: 'O documento não está disponível',
    audioFileNotAvailable: 'O áudio não está disponível',
    videoNotSupported: 'O navegador não suporta este vídeo',
    socketDisconnected: 'Houve um problema com a conexão. Por favor, atualize a página. Se o problema persistir, entre em contato com o administrador ou com a equipe de suporte da Indigitall.',
    fileTooBig: 'O arquivo é muito grande. Por favor, escolha outro arquivo.',
    fileNoValid: 'O arquivo selecionado não é válido. Por favor, escolha outro arquivo.',
    tooltipError: 'Ocorreu um erro. Mensagem não enviada.',
    noMatch: 'Página não encontrada. Clique no botão abaixo para ser redirecionado para a página inicial.',
    banUser: 'Houve um problema ao bloquear o usuário',
    requiredField: 'Campo obrigatório',
    generalError: 'Ocorreu um erro',
  },
  agentStats: {
    title: 'Estatísticas',
    waitingTime: 'Tempo médio de espera: ',
    maxWaitingTime: 'Tempo máximo de espera: ',
    average: 'Média',
    max: 'máx.',
    queuedUsers: 'Usuários na fila: ',
    onlineAgents: 'Agentes Online: ',
  },
  chats: {
    createLabel: 'Criar etiqueta',
    editLabel: 'Editar',
    deleteLabel: 'Excluir',
    placeholderSelect: 'Selecionar etiquetas...',
    search: 'Buscar...',
    ago: 'há',
    day: 'dia',
    month: 'mês',
    year: 'ano',
    conversations: 'Conversas',
    allConversations: 'Todas as conversas',
    conversation: 'Conversa',
    singleConversation: 'Conversa',
    lastMessage: 'Última mensagem',
    lastMsgAgent: 'Últ. msg agente',
    lastMsgUser: 'Últ. msg usuário',
    contactId: 'ID do Facebook',
    noNewConversations: 'Não aceitar novas conversas',
    acceptNewConversation: 'Aceitar novas conversas',
    writeNewMessage: 'Escrever uma nova mensagem',
    emptyMessagesTitle: 'Sem mensagens',
    emptyMessagesText: 'Selecione uma conversa no painel para ver as mensagens.',
    emptyConversations: 'Sem conversas',
    webchat: 'Web',
    whatsapp: 'WhatsApp',
    messenger: 'Messenger',
    showMoreMessages: 'Mostrar mais mensagens',
    endChatModal: {
      title: 'Deseja finalizar a conversa?',
      body: 'Se a conversa for finalizada, o usuário deixará de falar com um agente humano e voltará a falar com o chatbot até solicitar novamente falar com um agente.',
      cancel: 'Cancelar',
      end: 'Finalizar',
    },
    transferChatModal: {
      title: 'Deseja transferir a conversa?',
      body: 'Você pode transferir a conversa para os seguintes agentes, caso a operação não seja possível, a conversa permanecerá atribuída ao agente original.',
      cancel: 'Cancelar',
      noAvailableAgents: 'Não há mais agentes disponíveis no momento para transferir esta conversa.',
      end: 'Transferir',
    },
    template: 'Modelo',
    sent: 'enviado',
    sendTemplate: 'Enviar modelo',
    notAbleToShowMessage: 'Esta mensagem não pode ser exibida',
    notAbleToShowImageMessage: 'Esta mensagem é uma imagem e não pode ser exibida',
    notAbleToShowVideoMessage: 'Esta mensagem é um vídeo e não pode ser exibida',
    notAbleToShowDocMessage: 'Esta mensagem é um documento e não pode ser exibida',
    notAbleToShowLocationMessage: 'Esta mensagem contém uma localização e não pode ser exibida',
    notAbleToShowContactMessage: 'Esta mensagem contém informações de contato e não pode ser exibida',
    unknownDocumentCaption: 'Documento',
    myStatus: 'Meu status:',
    unavailable: 'Indisponível',
    available: 'Disponível',
    online: 'Online',
    unknown: 'Desconhecido',
    transferConvToolTip: 'Transferir conversa',
    endconvToolTip: 'Finalizar conversa',
    scrollToBotToolTip: 'Ir para o final',
    messageWasDeleted: 'Esta mensagem foi excluída',
    messageNotDelivered: 'Não foi possível enviar a mensagem, tente novamente mais tarde',
    sfDetails: 'Detalhes do Salesforce',
    banUser: 'Bloquear usuário',
    banConversation: 'Bloquear conversa',
    banConversationList: 'Lista de conversas bloqueadas',
    ban: 'Bloquear',
    conversationId: 'ID da conversa',
    date: 'Data',
    unbanTooltip: 'Remover bloqueio',
    reasonBlocking: 'Informe o motivo do bloqueio ou qualquer comentário sobre o mesmo',
    bannedSuccesfully: 'O usuário foi bloqueado',
    unBannedSuccessfully: 'O usuário foi desbloqueado',
    listBannedUsers: 'Lista de usuários bloqueados',
    areYouSureBanUserId: 'Você deseja bloquear a conversa com ID',
    infoBanUser: '? Esta conversa não poderá ser reaberta pelo usuário enquanto estiver bloqueada',
    reason: 'Motivo',
    logout: 'Sair',
    flowResponse: 'Resposta do fluxo',
    key: 'Chave',
    value: 'Valor',
  },
  messageInput: {
    placeholder: 'Digite sua mensagem...',
    sendTemplate: 'Enviar modelo',
    assignConvToMyself: 'Atribuir conversa para mim',
    disconnected: 'A conexão foi perdida',
  },
  attachMenu: {
    image: 'Imagem',
    video: 'Vídeo',
    document: 'Documento',
    audio: 'Áudio',
    attachButtonTooltip: 'Anexar arquivo',
  },
  attachImageModal: {
    title: 'Enviar imagem',
    button: 'Carregar imagem',
    send: 'Enviar',
    limitations: 'O tamanho do arquivo de imagem não pode ser superior a 5 MB',
    supportedFormat: 'Formatos de imagem suportados: .jpeg, .jpg e .png',
  },
  attachVideoModal: {
    title: 'Enviar vídeo',
    button: 'Carregar vídeo',
    send: 'Enviar',
    limitations: 'O tamanho do arquivo de vídeo não pode ser superior a 16 MB',
    supportedFormat: 'Formatos de vídeo suportados: .mp4 e .3gpp',
  },
  attachDocumentModal: {
    title: 'Enviar documento',
    button: 'Carregar documento',
    send: 'Enviar',
    limitations: 'O tamanho do arquivo do documento não pode ser superior a 100 MB',
    supportedFormat: '',
  },
  attachAudioModal: {
    title: 'Enviar áudio',
    button: 'Carregar áudio',
    send: 'Enviar',
    limitations: 'O tamanho do arquivo de áudio não pode ser superior a 16 MB',
    supportedFormat: 'Formatos de áudio suportados: .acc, .ogg, .amr, .mpeg e .mp4',
  },
  templateSelector: {
    title: 'Selecione o modelo',
    titleRadios: 'Você pode filtrar por papel de auditor',
    auditor: 'Auditor',
    all: 'Todos',
    tooltip: 'Usar modelo como mensagem',
    addTemplate: 'Adicionar modelo',
    deleteTemplate: 'Excluir modelo',
    sendTemplate: 'Enviar modelo',
    createdTemplate: 'O modelo foi criado',
    deletedTemplate: 'O modelo foi excluído',
    errorLoadingTemplates: 'Houve um problema ao carregar o modelo',
    errorCreatingTemplate: 'Houve um problema ao criar o modelo',
    errorDeletingTemplate: 'Houve um problema ao excluir o modelo',
    moreActions: 'Mais ações',
    viewTemplate: 'Ver modelo',
  },
  templateModal: {
    deleteTitle: 'Excluir modelo',
    areYouSureDelete: 'Tem certeza de que deseja excluir o modelo?',
    createTitle: 'Criar modelo',
    editTitle: 'Editar modelo',
    titlePlaceholder: 'Nome do modelo',
    messagePlaceholder: 'Modelo',
  },
  toast: {
    correctConvTransfer: 'A conversa foi transferida para outro agente corretamente.',
    sameAgentAfterTransfer: 'Não há agentes disponíveis. A conversa permanecerá aberta para este agente.',
    errorTransfer: 'Não há agentes disponíveis. A conversa será encerrada.',
    error500Attachfile: 'Ocorreu um erro. Tente novamente mais tarde e, se o problema persistir, entre em contato com a equipe de suporte técnico da Indigitall.',
    supervisorCloseConversation: 'Um supervisor encerrou a conversa ',
    errorLoadUsers: 'Erro ao tentar carregar os usuários disponíveis.',
  },
  buttons: {
    cancel: 'Cancelar',
    download: 'Baixar',
    downloadImage: 'Baixar imagem',
    downloadVideo: 'Baixar vídeo',
    downloadDocument: 'Baixar documento',
    downloadAudio: 'Baixar áudio',
    save: 'Salvar',
    backToHome: 'Voltar ao início',
    transfer: 'Transferir',
  },
  multimedia: {
    playVideo: 'Clique para ver o vídeo',
  },
  modalSF: {
    title: 'Abrir caso no Salesforce',
    body: 'Preencha as informações do caso no Salesforce',
    type: 'Selecione o tipo',
    reason: 'Selecione o motivo',
    priority: 'Selecione a prioridade',
    description: 'Preencha a descrição',
    labelType: 'Tipo',
    labelReason: 'Motivo',
    labelPriority: 'Prioridade',
    labelDescription: 'Descrição',
    labelExternalId: 'External Id',
    saveAndCloseConv: 'Salvar e Fechar',
  },
  modalCloseForm: {
    saveAndCloseConv: 'Salvar e Fechar',
  },
  localPush: {
    title: 'Indigitall Contact Center',
    body: 'Você tem mensagens não lidas',
  },
  supervisor: {
    noMessagesAvailable: 'Não há mensagens disponíveis. Volte atrás e abra a conversa novamente.',
    conversationsOf: 'Conversas do agente {agentName}',
    noConversationsToShow: 'Não há conversas para mostrar',
    agent: 'Agente #{agentId}',
    headerText: 'Supervisor de Conversas',
    agentsTab: 'Agentes',
    statsTab: 'Estatísticas',
    convsTab: 'Conversaciones sticky',
    online: 'Conectado',
    offline: 'Desconectado',
    seeConversations: 'Ver conversas',
    seeMessages: 'Ver mensagens',
    activeConversations: {
      singular: 'Conversa ativa',
      plural: 'Conversas ativas',
    },
    lastMessageReceived: 'Última mensagem recebida',
    lastMessageSent: 'Última mensagem enviada',
    lastTimeConnected: 'Conectado pela última vez em',
    notAvailableAKA: 'N/D',
    changeToAvailable: 'Alterar status para Disponível',
    changeToUnavaible: 'Alterar status para Indisponível',
    statistics: {
      titleAgents: 'Agentes',
      titleUsers: 'Usuários',
      titleConversations: 'Conversas',
      titleChannels: 'Canais',
      registeredAgentsPlural: 'agentes registrados',
      registeredAgentsSing: 'agente registrado',
      onlineAgentsPlural: 'agentes conectados',
      onlineAgentsSing: 'agente conectado',
      availableAgentsPlural: 'agentes disponíveis',
      availableAgentsSing: 'agente disponível',
      quededUsersPlural: 'usuários na fila',
      quededUsersSing: 'usuário na fila',
      avgWaitTimeStr: 'O tempo médio de espera é de',
      maxWaitTimeStr: 'O tempo máximo de espera é de',
      todayConvsAttendedPlural: 'conversas atendidas hoje',
      todayConvsAttendedSing: 'conversa atendida hoje',
      openedConvsNowPlural: 'conversas abertas agora',
      openedConvsNowSing: 'conversa aberta agora',
      convsPerAgentSing: 'conversa atribuída por agente',
      convsPerAgentPlural: 'conversas atribuídas por agente',
      convSing: 'conversa',
      convPlural: 'conversas',
    },
    modalTransfer: {
      title: 'Transferir conversa #{conversationId}',
      body: 'Selecione o agente para o qual deseja transferir esta conversa',
      selectPlaceholder: 'Digite o nome do agente para buscar',
      agent: 'Agente #{agentId}',
      selectNoOptionsMessage: 'Nenhum resultado encontrado',
    },
    goBack: 'Voltar',
  },
  emojiPicker: {
    title: 'Escolha um emoji',
    search: 'Buscar',
    notfound: 'Emoji não encontrado',
    skintext: 'Escolha o tom de pele',
    categories: {
      search: 'Resultados da busca',
      recent: 'Mais usados',
      people: 'Sorrisos & Pessoas',
      nature: 'Animais e Natureza',
      foods: 'Comida e Bebida',
      activity: 'Atividades',
      places: 'Viagens e Lugares',
      objects: 'Objetos',
      symbols: 'Símbolos',
      flags: 'Bandeiras',
      custom: 'Personalizado',
    },
  },
  login: {
    recoveryPass: 'Recuperar senha',
    title: 'Login',
    email: 'E-mail',
    pass: 'Senha',
    login: 'Entrar',
    doubleFactorSummary: 'Digite o código de verificação que você recebeu por e-mail, válido por:',
    verificationCode: 'Código de verificação',
    send: 'Enviar',
    backToLogin: 'Voltar ao login',
    refreshToken: 'Obter novo código',
    userLockedInactivity: 'Usuário bloqueado por inatividade, entre em contato com o administrador',
    tooOldPassword: 'Sua senha está muito antiga. Um e-mail foi enviado com instruções para alterá-la',
    tooManyAttempts: 'Muitas tentativas de login, usuário bloqueado, tente novamente mais tarde ou entre em contato com o administrador.',
    invalidBadges: 'Credenciais inválidas',

    insertVerificationCode: 'Insira o código de verificação recebido por e-mail, válido por:',
    invalidTotpError: 'O código inserido não é válido',
    tokenExpiredError: 'O código inserido expirou',
    sendNewCode: 'Obter novo código',
    isNotValid: 'Não é válido',
    invitationExpired: 'Convite expirado',
  },
  applications: {
    title: 'Selecione um projeto',
    noApplications: 'Não há projetos disponíveis',
    loadMore: 'Carregar mais',
    applicationSelected: 'Projeto selecionado',
    change: 'Alterar',
  },
  modalInstallApp: {
    title: 'Adicionar Indigitall Contact Center à sua Tela de início',
    body: `Para uma melhor experiência do usuário e mais facilidade de acesso,
    recomendamos que você instale nosso aplicativo no seu dispositivo móvel`,
    install: 'Instalar',
    cancel: 'Cancelar',
  },
  previewTemplateModal: {
    title: 'Pré-visualização do modelo',
    body: '',
    close: 'Fechar',
    send: 'Enviar',
  },
  drawer: {
    documentation: 'Documentação',
    suggestedReply: 'Resposta sugerida',
    summary: 'Resumo',
    useSuggestion: 'Usar sugestão',
    viewDoc: 'Ver documentação',
    notAvailableSuggestion: 'Este é um recurso premium. Entre em contato com nossa equipe de vendas.',
    notAvailableSummary: 'Não há resumo disponível',
    sources: 'Fontes',
    notAvailableSources: 'Não há fontes disponíveis.'
  },
  stickyTable: {
    free: 'Liberar',
    assignAgent: 'Atribuir Agente',
    search: 'Buscar Agente',
    contact: 'Contato',
    agent: 'Agente',
    actions: 'Ações',
    searchDots: 'Buscar...',
  },
  stickyModal: {
    titleAdd: 'Selecione o Agente Sticky',
    titleDelete: 'Confirmar Liberação',
    bodyAddAgent: 'Você pode selecionar o agente sticky que irá gerenciar as conversas selecionadas.',
    bodyDeleteAgent: 'Se você pressionar aceitar, as conversas selecionadas serão liberadas.',
    cancel: 'Cancelar',
    accept: 'Aceitar',
    noAvailableAgents: 'Não há mais agentes disponíveis no momento para transferir esta conversa.',
    end: 'Transferir',
  },
  
};