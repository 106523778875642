import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationCircle } from 'react-icons/fa';
import { WarningContainer, WarningText } from './styled';



const WarningMessage = ({ message }) => {
  return (
    <WarningContainer>
      <FaExclamationCircle size={16} />
      <WarningText>{message}</WarningText>
    </WarningContainer>
  );
};
WarningMessage.propTypes = {
  message: PropTypes.string,
};

export default WarningMessage;
