import * as api from '../api';

class ContactsService {
  getContacts = async (applicationId, limit = 100, find = '', lastId, isDescending) => {
    try {
      const contacts = await api.getContacts(applicationId, limit, find, lastId, isDescending);
      if (contacts && contacts.data) {
        return contacts.data;
      }
      return contacts;
    } catch (error) {
      throw new Error(error);
    }
  };

  getPayloadURL = (payload) => {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(payload);
  };

  getSummaryHistory = async (idConv) => {
    try {
      const summary = await api.getSummaryHistory(idConv);
        return summary;
    } catch (error) {
      throw new Error(error);
    }
  };

}

const instance = new ContactsService();

export default instance;
