import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const MenuContainer = styled.div`
  padding: 25px;
  width: 850px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const StyledBox = styled(Box)`
  padding: 16px;
  background-color: ${(props) => props.theme?.colors?.background || '#f9f9f9'};
  border-top: 1px solid ${(props) => props.theme?.colors?.divider || '#ddd'};
`;

export const SectionContainer = styled.div`
  margin-bottom: ${(props) => props.noBorder ? '0px' : '16px'};
  padding: 8px 0;
  border-bottom: ${(props) => props.noBorder ? '0px' : `1px solid ${props.theme?.colors?.divider || '#ddd'}`};
`;


export const SectionTitle = styled(Typography)`
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => props.theme?.colors?.primary?.main || '#333'};
`;

export const SectionContent = styled(Typography)`
  font-size: 0.85rem;
  color: ${(props) => props.theme?.colors?.text?.secondary || '#555'};
`;

export const StyledLink = styled(Link)`
  margin-bottom: 16px;
  font-size: 0.85rem;
  color: ${(props) => props.theme?.colors?.primary?.main || '#1976d2'};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme?.colors?.primary?.dark || '#115293'};
  }
`;
export const StyledA = styled.a`
  margin-top: 15px;
`;
export const StyledButton = styled.button`
  border: solid 2px;
  border-radius: 4px;
  padding: 0 16px;
  margin-top: 15px;
  /* width: 100%; */
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.disabled
      ? props.theme?.colors?.grey?.[300] || '#e0e0e0'
      : props.theme?.colors?.primary?.main || '#1976d2'};
  color: ${(props) =>
    props.disabled
      ? props.theme?.colors?.text?.disabled || '#9e9e9e'
      : '#fff'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-color: ${(props) =>
    props.disabled
      ? props.theme?.colors?.grey?.[300] || '#e0e0e0'
      : props.theme?.colors?.primary?.main || '#1976d2'};
  box-shadow: ${(props) =>
    props.disabled
      ? 'none'
      : `0px 1px 5px 0px rgba(0, 0, 0, 0.2),
         0px 2px 2px 0px rgba(0, 0, 0, 0.14),
         0px 3px 1px -2px rgba(0, 0, 0, 0.12)`};

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme?.colors?.grey?.[300] || '#e0e0e0'
        : props.theme?.colors?.primary?.dark || '#115293'};
    border-color: ${(props) =>
      props.disabled
        ? props.theme?.colors?.grey?.[300] || '#e0e0e0'
        : props.theme?.colors?.primary?.dark || '#115293'};
  }

  &:active {
    background-color: ${(props) =>
      props.disabled
        ? props.theme?.colors?.grey?.[300] || '#e0e0e0'
        : props.theme?.colors?.primary?.darker || '#0d3b66'};
    border-color: ${(props) =>
      props.disabled
        ? props.theme?.colors?.grey?.[300] || '#e0e0e0'
        : props.theme?.colors?.primary?.darker || '#0d3b66'};
    color: ${(props) =>
      props.disabled ? '#9e9e9e' : '#fff'};
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ocupa todo el espacio disponible */
`;

export const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid ${(props) => props.theme?.colors?.primary?.main || '#1976d2'};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: ${(props) => props.theme?.colors?.text?.primary || '#000'};
  padding: 8px;

  &:hover {
    color: ${(props) => props.theme?.colors?.secondary?.main || '#f50057'};
  }

  &:focus {
    outline: none;
  }
`;