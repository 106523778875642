import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Routes from '../routes';
import { theme, GlobalStyle } from '../../../config/styled';
import { Toast } from '../../organisms';
import { openDatabase, clearBufferMessagesDB } from '../../../db/dbUtils';


import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  useEffect(() => {
    const initDB = async () => {
      try {
        await openDatabase();
        console.log('IndexedDB ready');

        // Limpiar mensajes pendientes al iniciar la app
        await clearBufferMessagesDB();
        console.log('Buffer de mensajes limpiado');
      } catch (error) {
        console.error('Error inicializando IndexedDB:', error);
      }
    };

    initDB();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes />
      <Toast />
    </ThemeProvider>
  );
};

export default App;
