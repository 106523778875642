import styled from 'styled-components';
import "emoji-mart/css/emoji-mart.css";
import { TextareaAutosize } from '@mui/material';

const SendInputStyled = styled.div`
  min-height: 66px;
  display: flex !important;
  background-color: white;
  align-items: flex-end !important;
  padding: 0 16px 10px 4px;
  border-top: 1px solid ${(props) => props.theme.colors.grey[400]};
  -webkit-box-shadow: 0px 0px 5px 0px rgba(97, 97, 97, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(97, 97, 97, 1);
  box-shadow: 0px 0px 5px 0px rgba(97, 97, 97, 1);
  position: relative;
  z-index: 8;
  
  .template-button-send {
    border: solid;
    border-width: 2px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.secondary.main};
    padding: 5px 16px;
    min-width: 100px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.grey[100]};
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

    &:hover {
      color: ${(props) => props.theme.colors.secondary.main};
      border-color: ${(props) => props.theme.colors.secondary.main};
      cursor: pointer;
    }

    &:active {
      background-color: ${(props) => props.theme.colors.grey[300]};
      color: ${(props) => props.theme.colors.primary.main};
    }
  }
  .assign-self-button {
    border: solid;
    border-width: 2px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.secondary.main};
    padding: 0 16px;
    width: 100%;
    min-width: 100px;
    margin-bottom: 7px;
    margin-left: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.grey[100]};
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

    &:hover {
      color: ${(props) => props.theme.colors.secondary.main};
      border-color: ${(props) => props.theme.colors.secondary.main};
      cursor: pointer;
    }

    &:active {
      background-color: ${(props) => props.theme.colors.grey[300]};
      color: ${(props) => props.theme.colors.primary.main};
    }
  }
  .selector {
    opacity: ${({ showAttachMenu }) => (showAttachMenu ? '1' : '0')};
    display: ${({ showAttachMenu }) => (showAttachMenu ? 'auto' : 'none')};
    padding-bottom: 16px;
    background-color: white;
    min-width: 216px;
    position: absolute;
    bottom: 61px;
    left: 0;
    z-index: 7;
    -webkit-box-shadow: 3px -3px 8px 0px rgba(170, 170, 170, 0.84);
    box-shadow: 3px -3px 8px 0px rgba(170, 170, 170, 0.84);

    ul {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      ${({ showAttachMenu }) => (showAttachMenu ? 'opacity: 1' : 'opacity: 0')};

      li {
        .menu-button {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 20px 32px 16px 16px;

          .menu-button-icon {
            margin-right: 8px;
            margin-bottom: 4px;
          }
        }

        &:hover {
          background-color: ${(props) => props.theme.colors.grey[300]};
          color: ${(props) => props.theme.colors.primary.main};
          padding-left: 22px;
          cursor: pointer;

          .menu-button-icon {
            fill: ${(props) => props.theme.colors.primary.main};
            margin-right: 22px;
          }
        }
      }
    }
  }

  .attach-button, .fragments-button {
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    margin-right: 4px;

    &:active {
      background-color: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.white;
        }
        return theme.colors.grey[300];
      }};
    }

    .attach-icon {
      fill: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.grey[100];
        }
        return ({ showAttachMenu }) => (showAttachMenu ? theme.colors.secondary.main : theme.colors.grey[200]);
      }};
      width: 28px;
      height: 28px;

      &:hover {
        fill: ${({ theme, disabled }) => {
          if (disabled) {
            return theme.colors.grey[200];
          }
          return theme.colors.secondary.main;
        }};

        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
    .ai-icon {
      fill: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.grey[100];
        }
        return ({ showAttachMenu }) => (showAttachMenu ? theme.colors.secondary.main : theme.colors.grey[200]);
      }};
      width: 28px;
      height: 28px;

      &:hover {
        fill: ${({ theme, disabled }) => {
          if (disabled) {
            return theme.colors.grey[200];
          }
          return theme.colors.secondary.main;
        }};

        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
    .fragments-icon {
      fill: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.grey[100];
        }
        return ({ showTemplateSelector }) => (showTemplateSelector ? theme.colors.secondary.main : theme.colors.grey[200]);
      }};
      width: 28px;
      height: 28px;

      &:hover {
        fill: ${({ theme, disabled }) => {
          if (disabled) {
            return theme.colors.grey[200];
          }
          return theme.colors.secondary.main;
        }};

        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  .fragment-selector {
    position: absolute;
    opacity: ${({ showTemplateSelector }) => (showTemplateSelector ? '1' : '0')};
    display: ${({ showTemplateSelector }) => (showTemplateSelector ? 'auto' : 'none')};
    background-color: white;
    width: 60rem;
    bottom: 61px;
    left: 0;
    z-index: 7;
    -webkit-box-shadow: 3px -3px 8px 0px rgba(170, 170, 170, 0.84);
    box-shadow: 3px -3px 8px 0px rgba(170, 170, 170, 0.84);

    .fragment-selector-header {
      font-size: 1.25rem;
      padding: 20px 0 20px 20px;
      display: flex;
      align-items: center;
    }
    .fragment-selector-title {
      color: ${({ theme }) => theme.colors.primary.main};
      font-weight: 700;
    }
    .add-fragment-button {
      fill: ${({theme}) => theme.colors.grey[300]};

      &:hover {
        cursor: pointer;
      }
    }

    ul {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      list-style-type: none;
      max-height: 30rem;
      overflow: auto;

      .fragment-element {
        padding: 0.25rem 20px;
        display: grid;
        line-height: 20px;
        grid-auto-flow: column;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: end;

        .fragment-element-title {
          font-weight: 700;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .fragment-element-message {
          font-style: oblique;
          color: ${({theme}) => theme.colors.grey[200]};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .delete-button {
          color: ${({theme}) => theme.colors.grey[300]};
          grid-row: 1 / 3;
          display: none;
          justify-self: end;
        }

        &:hover {
          background-color: ${(props) => props.theme.colors.grey[400]};
          padding-left: 22px;
          cursor: pointer;

          .delete-button {
            display: inline-block;
          }
        }
      }
    }
  }

  .div-textarea {
    width: 100%;
  }
  .send-button {
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    margin-left: 20px;

    &:active {
      background-color: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.white;
        }
        return theme.colors.grey[300];
      }};
    }

    .send-icon {
      fill: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.grey[200];
        }
        return theme.colors.secondary.main;
      }};
      width: 24px;
      height: 24px;

      &:hover {
        fill: ${({ theme, disabled }) => {
          if (disabled) {
            return theme.colors.grey[200];
          }
          return theme.colors.primary.main;
        }};
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }
  }
  .emoji-button {
    padding-right: 2rem;
  }
  .emoji-picker {
    position: absolute;
    left: 0;
    bottom: 100%;
  }
  .emoji-icon {
    fill: ${({ theme, disabled }) => {
      if (disabled) {
        return theme.colors.grey[200];
      }
      return theme.colors.secondary.main;
    }};
    width: 24px;
    height: 24px;
    &:hover {
      fill: ${({ theme, disabled }) => {
        if (disabled) {
          return theme.colors.grey[200];
        }
        return theme.colors.primary.main;
      }};
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }
  .flex-form-radio {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .radio-title {
    margin-left: 20px;
  }
  .radio-content {
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
  }
  .label-radio {
    margin-right: 5px;
  }
  .form-radios {
    margin-bottom: 10px;
  }
  .input-radio {
    cursor: pointer;
  }
`;

const DropdownMenu = styled.div`
  position: fixed;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;
const MenuItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;



/* flex: 1;
    border: none;
    min-height: 40px;
    max-height: 96px;
    resize: none;
    padding: 9px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    overflow-y: auto;
    &:hover {
      border-bottom: ${({ theme, disabled }) => {
        if (disabled) {
          return `1px solid ${theme.colors.grey[300]}`;
        }
        return `1px solid ${theme.colors.primary.darker}`;
      }};
    } */
      const StyledTextArea = styled(TextareaAutosize)`
        flex: 1;
        min-height: 18px;
        max-height: 72px;
        padding: 8px;
        font-size: 18px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        color: ${({ hideText }) => (hideText ? 'white' : 'auto')}; /* Cambia el color basado en hideText */
        resize: none;
        border: 0px;
      `;




const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;




const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.3); // oscuro y traslúcido
    pointer-events: none; // no bloquea eventos, solo es visible
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

`;

const Blocker = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    bottom: 0;
    pointer-events: all; // bloquea todos los eventos de mouse
`;


export { SendInputStyled, DropdownMenu, MenuItem, StyledTextArea, Wrapper, Overlay, Blocker };
